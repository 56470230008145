import { default as indexpd6GwpLqWSMeta } from "/vercel/path0/pages/experiences/index.vue?macro=true";
import { default as _91_46_46_46slug_93287XBj9E89Meta } from "/vercel/path0/pages/hobbies/[...slug].vue?macro=true";
import { default as indexhcEoAD4YEWMeta } from "/vercel/path0/pages/hobbies/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexssS838kyt4Meta } from "/vercel/path0/pages/projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93wRYifHvkAKMeta } from "/vercel/path0/pages/publications/[...slug].vue?macro=true";
import { default as indexHGVmmQKzcpMeta } from "/vercel/path0/pages/publications/index.vue?macro=true";
export default [
  {
    name: "experiences___it",
    path: "/experiences",
    component: () => import("/vercel/path0/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: "experiences___en",
    path: "/en/experiences",
    component: () => import("/vercel/path0/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: "experiences___es",
    path: "/es/experiences",
    component: () => import("/vercel/path0/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: "hobbies-slug___it",
    path: "/hobbies/:slug(.*)*",
    component: () => import("/vercel/path0/pages/hobbies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "hobbies-slug___en",
    path: "/en/hobbies/:slug(.*)*",
    component: () => import("/vercel/path0/pages/hobbies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "hobbies-slug___es",
    path: "/es/hobbies/:slug(.*)*",
    component: () => import("/vercel/path0/pages/hobbies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "hobbies___it",
    path: "/hobbies",
    component: () => import("/vercel/path0/pages/hobbies/index.vue").then(m => m.default || m)
  },
  {
    name: "hobbies___en",
    path: "/en/hobbies",
    component: () => import("/vercel/path0/pages/hobbies/index.vue").then(m => m.default || m)
  },
  {
    name: "hobbies___es",
    path: "/es/hobbies",
    component: () => import("/vercel/path0/pages/hobbies/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___it",
    path: "/projects",
    component: () => import("/vercel/path0/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/vercel/path0/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___es",
    path: "/es/projects",
    component: () => import("/vercel/path0/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "publications-slug___it",
    path: "/publications/:slug(.*)*",
    component: () => import("/vercel/path0/pages/publications/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "publications-slug___en",
    path: "/en/publications/:slug(.*)*",
    component: () => import("/vercel/path0/pages/publications/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "publications___it",
    path: "/publications",
    component: () => import("/vercel/path0/pages/publications/index.vue").then(m => m.default || m)
  },
  {
    name: "publications___en",
    path: "/en/publications",
    component: () => import("/vercel/path0/pages/publications/index.vue").then(m => m.default || m)
  }
]