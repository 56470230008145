import { defineNuxtPlugin } from '#app/nuxt'
import { LazyActionButton, LazyBlockQuote, LazyBlogPageCard, LazyBookList, LazyBrandList, LazyCertificationCard, LazyExperienceCard, LazyExperienceSummary, LazyHobbiesList, LazyHobbyCard, LazyItemsList, LazyItemsListCard, LazyLanguageCard, LazyLongText, LazyOnepageContacts, LazyOnepageIntro, LazyPostCard, LazyProjectCard, LazyRichtextRenderer, LazySkillsList, LazySpokenLanguages, LazyStoryblokImage } from '#components'
const lazyGlobalComponents = [
  ["ActionButton", LazyActionButton],
["BlockQuote", LazyBlockQuote],
["BlogPageCard", LazyBlogPageCard],
["BookList", LazyBookList],
["BrandList", LazyBrandList],
["CertificationCard", LazyCertificationCard],
["ExperienceCard", LazyExperienceCard],
["ExperienceSummary", LazyExperienceSummary],
["HobbiesList", LazyHobbiesList],
["HobbyCard", LazyHobbyCard],
["ItemsList", LazyItemsList],
["ItemsListCard", LazyItemsListCard],
["LanguageCard", LazyLanguageCard],
["LongText", LazyLongText],
["OnepageContacts", LazyOnepageContacts],
["OnepageIntro", LazyOnepageIntro],
["PostCard", LazyPostCard],
["ProjectCard", LazyProjectCard],
["RichtextRenderer", LazyRichtextRenderer],
["SkillsList", LazySkillsList],
["SpokenLanguages", LazySpokenLanguages],
["StoryblokImage", LazyStoryblokImage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
